import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/(authenticated)/abacus/[tableId]": [4,[2]],
		"/(authenticated)/announcements": [5,[2]],
		"/(authenticated)/articles": [6,[2]],
		"/(authenticated)/articles/full_text": [8,[2]],
		"/(authenticated)/articles/[articleId]": [7,[2]],
		"/auth/login": [57],
		"/auth/logout": [58],
		"/(authenticated)/categories": [9,[2]],
		"/(authenticated)/categories/backfill": [11,[2]],
		"/(authenticated)/categories/backfill/confirm": [12,[2]],
		"/(authenticated)/categories/backfill/status": [13,[2]],
		"/(authenticated)/categories/new": [14,[2]],
		"/(authenticated)/categories/rules": [15,[2]],
		"/(authenticated)/categories/rules/[ruleId]": [16,[2]],
		"/(authenticated)/categories/topics": [17,[2]],
		"/(authenticated)/categories/topics/[topicId]": [18,[2]],
		"/(authenticated)/categories/[categoryId]": [10,[2]],
		"/(authenticated)/dashboard": [19,[2]],
		"/(authenticated)/dashboard/client": [20,[2]],
		"/(authenticated)/dashboard/headlines": [21,[2]],
		"/(authenticated)/dashboard/push": [22,[2]],
		"/(authenticated)/experiments": [23,[2]],
		"/(authenticated)/experiments/legacy/[experimentName]": [25,[2]],
		"/(authenticated)/experiments/[experimentName]": [24,[2]],
		"/(authenticated)/feedback": [26,[2]],
		"/(authenticated)/feedback/[feedbackId]": [27,[2]],
		"/(authenticated)/gates": [28,[2]],
		"/(authenticated)/gates/[gateName]": [29,[2]],
		"/(authenticated)/headlines": [30,[2]],
		"/(authenticated)/headlines/[headlineId]": [31,[2]],
		"/(authenticated)/notifications/manual": [32,[2]],
		"/(authenticated)/notifications/sampler": [33,[2]],
		"/(authenticated)/publishers": [34,[2]],
		"/(authenticated)/publishers/new": [36,[2]],
		"/(authenticated)/publishers/[publisherId]": [35,[2]],
		"/(authenticated)/ratings/[ratingId]": [37,[2]],
		"/(authenticated)/regions": [38,[2]],
		"/(authenticated)/regions/new": [40,[2]],
		"/(authenticated)/regions/[regionId]": [39,[2]],
		"/(authenticated)/reports": [41,[2]],
		"/(authenticated)/reports/[reportId]": [42,[2]],
		"/(authenticated)/site": [43,[2]],
		"/(authenticated)/site/shopping_modules": [44,[2]],
		"/(authenticated)/site/shopping_modules/[id]": [45,[2]],
		"/(authenticated)/site/web_modules": [46,[2]],
		"/(authenticated)/site/web_modules/[id]": [47,[2]],
		"/(authenticated)/test_prompt": [48,[2]],
		"/(authenticated)/test_users": [49,[2]],
		"/(authenticated)/tools/id/[id]": [50,[2]],
		"/(authenticated)/tools/knobs": [51,[2]],
		"/(authenticated)/tools/schema": [52,[2]],
		"/(authenticated)/users": [53,[2]],
		"/(authenticated)/users/[userId]": [54,[2]],
		"/(authenticated)/writers": [55,[2]],
		"/(authenticated)/writers/[writerId]": [56,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';