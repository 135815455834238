import type { HandleClientError } from '@sveltejs/kit';

import { HttpError } from '$lib/errors';

export const handleError: HandleClientError = async ({ error, message }) => {
  if (error instanceof HttpError) {
    return error;
  }

  return {
    message,
    statusCode: 500,
    statusText: 'Internal Server Error'
  };
};
